import { useSelectedServicoStore } from '@/pinia/crudServico/useServicoStore';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

const formServicoGuard = (
  to: RouteLocationNormalized, 
  from: RouteLocationNormalized, 
  next: NavigationGuardNext
) => {
    const storeServico = useSelectedServicoStore();    
    const byPush = storeServico.isPush;
            
    if (!byPush && to.name !== 'cadastros-servicos-list') {
      next({ name: 'cadastros-servicos-list' });
    } else {
      next();
    }
}

export {
  formServicoGuard
}