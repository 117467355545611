import { useEstoqueStore } from '@/pinia/estoque/useEstoqueStore';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

const estoqueGuard = (
  to: RouteLocationNormalized, 
  from: RouteLocationNormalized, 
  next: NavigationGuardNext
) => {
    const storeEstoque = useEstoqueStore();    
    const byPush = storeEstoque.getByPush;
            
    if (!byPush && to.name !== 'sugestao-pedidos') {
      next({ name: 'sugestao-pedidos' });
    } else {
      next();
    }
}

export {
  estoqueGuard
}