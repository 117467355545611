import { defineStore } from 'pinia';

export const useSelectedUsuarioStore = defineStore('useSelectedUsuarioStore', {
  state: () => ({
    selecteUsuario: null as string | null,
    isPush: false as boolean
  }),
  actions: {
    setByPush(value: boolean) {
      this.isPush = value;
    },
    setSelectedUsuario(codUsuario: string | null) {
      this.selecteUsuario = codUsuario
      this.setByPush(true)
    },
    clearSelectedUsuario(){
      this.selecteUsuario = null
    }
  },
  getters: {
    getSelectedUsuario: (state) => state.selecteUsuario,
    byPush: (state) => state.isPush
  },
});

export type UsuarioStore = ReturnType<typeof useSelectedUsuarioStore>;
