import { useSelectedUsuarioStore } from '@/pinia/crudUsuario/useUsuarioStore';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

const formUsuarioGuard = (
  to: RouteLocationNormalized, 
  from: RouteLocationNormalized, 
  next: NavigationGuardNext
) => {
    const storeUsuario = useSelectedUsuarioStore();    
    const byPush = storeUsuario.isPush;
            
    if (!byPush && to.name !== 'cadastros-usuario-list') {
      next({ name: 'cadastros-usuario-list' });
    } else {
      next();
    }
}

export {
  formUsuarioGuard
}