import { defineStore } from 'pinia';

interface Params {
  codSugestaoPedido: number,
  codconcessionaria: number,
  hash_pedido: string,
  nomeConcessionaria: string
}

export const useEstoqueStore = defineStore('useEstoqueStore', {
  state: () => ({
    title: '',
    codSugestaoPedido: 0,
    codconcessionaria: 0,
    hash_pedido: '',
    byPush: false,
    nomeConcessionaria: ''
  }),
  actions: {    
    setParamsEstoque(params: Params) {
      this.codSugestaoPedido= params.codSugestaoPedido,
      this.codconcessionaria= params.codconcessionaria,
      this.hash_pedido= params.hash_pedido,
      this.nomeConcessionaria= params.nomeConcessionaria
    },
    setTitle(title: string){
      this.title = title
    },
    setByPush(byPush : boolean){
      this.byPush = byPush
    }
  },
  getters: {
    getParamsEstoque: (state) => {
      return {
        codSugestaoPedido: state.codSugestaoPedido,
        codconcessionaria: state.codconcessionaria,
        hash_pedido: state.hash_pedido,
        nomeConcessionaria: state.nomeConcessionaria
      }
    },
    getTitle: (state) => state.title,
    getByPush: (state) => state.byPush,
  },
});

