import { defineStore } from 'pinia';

export const useSelectedConsultorStore = defineStore('useSelectedConsultorStore', {
  state: () => ({
    selecteConsultor: null as string | null,
    isPush: false as boolean
  }),
  actions: {
    setByPush(value: boolean) {
      this.isPush = value;
    },
    setSelectedConsultor(codConsultor: string | null) {
      this.selecteConsultor = codConsultor
      this.setByPush(true)
    },
    clearSelectedConsultor(){
      this.selecteConsultor = null
    }
  },
  getters: {
    getSelectedConsultor: (state) => state.selecteConsultor,
    byPush: (state) => state.isPush
  },
});

export type ConsultorStore = ReturnType<typeof useSelectedConsultorStore>;
