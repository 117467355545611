import { defineStore } from 'pinia';

export const useSelectedGrupoStore = defineStore('useSelectedGrupoStore', {
  state: () => ({
    selecteGrupo: null as string | null,
    isPush: false as boolean
  }),
  actions: {
    setByPush(value: boolean) {
      this.isPush = value;
    },
    setSelectedGrupo(codGrupo: string | null) {
      this.selecteGrupo = codGrupo
      this.setByPush(true)
    },
    clearSelectedGrupo(){
      this.selecteGrupo = null
    }
  },
  getters: {
    getSelectedGrupo: (state) => state.selecteGrupo,
    byPush: (state) => state.isPush
  },
});

export type GrupoStore = ReturnType<typeof useSelectedGrupoStore>;
