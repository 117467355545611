import { defineStore } from 'pinia';

export const useSelectedServicoStore = defineStore('useSelectedServicoStore', {
  state: () => ({
    selecteServico: null as string | null,
    isPush: false as boolean
  }),
  actions: {
    setByPush(value: boolean) {
      this.isPush = value;
    },
    setSelectedServico(codServico: string | null) {
      this.selecteServico = codServico
      this.setByPush(true)
    },
    clearSelectedServico(){
      this.selecteServico = null
    }
  },
  getters: {
    getSelectedServico: (state) => state.selecteServico,
    byPush: (state) => state.isPush
  },
});

export type ServicoStore = ReturnType<typeof useSelectedServicoStore>;
